<template>
	<b-row>
		<b-col class="d-flex" cols="12">
			<div class="d-flex" style="flex: 4">
				<div style="width: 50%; background: #e4e4e4"></div>
				<div class="px-2" style="width: 50%">
					<div class="pt-1" style="display: flex; align-items: center">
						<span style="width: 35%">开始时间</span>
						<b-form-input type="date"></b-form-input>
					</div>
					<div class="pt-1" style="display: flex; align-items: center">
						<span style="width: 35%">结束时间</span>
						<b-form-input type="date"></b-form-input>
					</div>
					<div class="pt-1" style="display: flex; justify-content: space-between">
						<b-button> 人脸库</b-button>
						<b-button> 检索</b-button>
					</div>
				</div>
			</div>
			<div style="flex: 6; background: #e4e4e4"></div>
		</b-col>
		<b-col class="my-2 d-flex align-items-center" cols="12">
			<div style="width: 100%; height: 1px; background: #e4e4e4"></div>
		</b-col>
		<b-col cols="12">
			<h4 class="pb-2">检索结果</h4>
			<div class="pics">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</b-col>
	</b-row>
</template>

<script>
	export default {
		name: "index",
	};
</script>

<style lang="scss" scoped>
	.pics {
		display: grid;
		grid-template-columns: repeat(8, 1fr);
		gap: 3rem;

		div {
			height: 0;
			padding-bottom: 100%;
			background: #1c6ca1;
		}
	}
</style>
